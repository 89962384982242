html {
  scroll-behavior: smooth;
  /* user-select: none; */
}
body {
  margin: 0;
  font-family: "Mulish", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #09080c !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.nft-border {
  /* 不能與 .nft-text 共存，可以當作外層 */
  padding: 0;
  margin: 8px;
  border: 8px solid transparent; /* 相對低 */
  border-radius: 48px; /* 至少兩倍 */
  background-image: linear-gradient(#09080c, #09080c),
    linear-gradient(74.31deg, #85ffc4 0%, #5cc6ff 45.83%, #bc85ff 80.73%);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.nft-background {
  padding: 16px;
  background: linear-gradient(270deg, #3e78ff 0%, #5cc6ff 100%);
}

.nft-text {
  padding: 16px;
  background: linear-gradient(
    74.31deg,
    #85ffc4 0%,
    #5cc6ff 45.83%,
    #bc85ff 80.73%
  );
  -webkit-background-clip: text;
  color: transparent;
}
